import { useContext, useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "~/auth";
import SelectCard from "~/components/select/SelectCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDateTime } from "~/helpers/timestampFormat";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { JobDocument, useWorkOrdersQuery, WorkOrdersQueryVariables, WorkOrderStatus } from "~/services/graphql";
import { updatePageButtons } from "~/store/layoutSlice";

interface ProductionLinesProps {
	jobs: JobDocument[];
}

const ProductionLines = ({ jobs }: ProductionLinesProps) => {
	const numberOfProductionLines = useAppSelector((state) => state.settings.numberOfProductionLines);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const authContext = useContext(AuthContext);

	const jobIds = jobs.map((job) => job.id);

	const navigateToLine = (line: number) => {
		return navigate("/count/line/" + String(line));
	};

	useEffect(() => {
		// if there is only 1 line then redirect to that line
		if (numberOfProductionLines === 1) {
			return navigateToLine(1);
		}

		dispatch(updatePageButtons([]));
		//)
	}, [numberOfProductionLines]);

	useEffect(() => {
		localStorage.setItem("lastPage", "count/lines");
	}, []);

	let workOrdersVariables: WorkOrdersQueryVariables = {
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: [WorkOrderStatus.New, WorkOrderStatus.InProgress],
		first: 50,
		jobId: jobIds,
	};

	const { data, isLoading, error } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (isLoading) return <div>Loading work orders...</div>;

	if (error) return <div>Error: Getting WorkOrders</div>;
	//if (error) return <Alert severity="info">{error.message}</Alert>;

	//find if there is a workorder assigned to a line

	return (
		<SpacedGridContainer alignItems="stretch">
			{Array.apply(0, Array(numberOfProductionLines)).map(function (_x, i) {
				let productionLine = i + 1;
				let workOrder = data?.workOrders?.nodes?.find((wo) => wo.lineNumber === productionLine);
				let job = jobs.find((job) => job.id === workOrder?.jobId);
				return (
					<SpacedGridItem maxCols={3} smMargin={false} key={productionLine}>
						<SelectCard onClick={() => navigateToLine(productionLine)}>
							<Grid container spacing={1} sx={{ minHeight: "120px" }}>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography gutterBottom={false} variant="h5" component="h5" color="textPrimary" sx={{ textAlign: "center" }}>
												{`Line ${productionLine}`}
											</Typography>
										</Grid>
										{workOrder ? (
											<>
												<Grid item xs={12}>
													<Typography variant="h6" component="h6" color="primary">
														{job?.item?.name}
													</Typography>
												</Grid>
												<Grid item xs={3}>
													<b>Workorder:</b>
													<br /> {workOrder.erpId || ""}
												</Grid>
												<Grid item xs={3}>
													<b>Target</b> <br />
													{workOrder.containerQuantity}
												</Grid>
												<Grid item>
													<b>Started</b>
													<br />
													{stringFormatDateTime(workOrder.startDate)}
												</Grid>
											</>
										) : (
											<>
												<Grid item xs={12}>
													<b>No Current Workorder</b>
												</Grid>
											</>
										)}
									</Grid>
								</Grid>
							</Grid>
						</SelectCard>
					</SpacedGridItem>
				);
			})}
		</SpacedGridContainer>
	);
};

export default ProductionLines;
