import { useContext, useState } from "react";

import { Alert, Chip, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";

import { AuthContext } from "~/auth";
import { getWorkOrderChipColor, getWorkOrderStatus } from "~/pages/Count/helpers/workOrderStatusMaps";
import SelectCard from "~/components/select/SelectCard";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDate } from "~/helpers/timestampFormat";
import { useAppSelector } from "~/hooks/reduxHooks";
import { JobDocument, useWorkOrdersQuery, WorkOrdersQueryVariables, WorkOrderStatus } from "~/services/graphql";

interface SelectWorkorderProps {
	handleInputChange: (workOrderId: string, jobId: string) => void;
	jobs: JobDocument[];
	workOrderStatuses: WorkOrderStatus[];
}

const SelectWorkorder = ({ handleInputChange, jobs, workOrderStatuses }: SelectWorkorderProps) => {
	const viewType = useAppSelector((state) => state.layout.viewType);
	const authContext = useContext(AuthContext);

	const jobIds = jobs.map((job) => job.id);

	const columns: GridColDef[] = [
		{ field: "id", headerName: "id", flex: 1 },
		{ field: "jobId", headerName: "jobId", flex: 1 },
		{ field: "erpIp", headerName: "WO#", flex: 1 },
		{ field: "genusCode", headerName: "Genus", flex: 1 },
		{ field: "lineNumber", headerName: "Line", flex: 1 },
		{ field: "item", headerName: "Item", flex: 1 },
		{ field: "upc", headerName: "UPC", flex: 1 },
		{ field: "name", headerName: "Job Name", flex: 1 },
		{ field: "jobStatus", headerName: "jobStatus", flex: 1 },
		{
			field: "workOrderStatus",
			headerName: "wostatus",
			flex: 1,
			valueFormatter: (value) => {
				return getWorkOrderStatus(value as WorkOrderStatus);
			},
		},
		{ field: "jobType", headerName: "jobType", flex: 1 },
		{ field: "locationId", headerName: "locationId", flex: 1 },
		{ field: "unitOfMeasure", headerName: "unitOfMeasure", flex: 1 },
		{ field: "containerQuantity", headerName: "containerQuantity", flex: 1 },
		{ field: "productionQuantity", headerName: "productionQuantity", flex: 1 },
		{ field: "quantityProduced", headerName: "quantityProduced", flex: 1 },
		{ field: "startDate", headerName: "startDate", flex: 1, valueFormatter: (value) => (value ? stringFormatDate(value) : "") },
		{ field: "deliveryDate", headerName: "deliveryDate", flex: 1 },
		{ field: "created", headerName: "created", flex: 1 },
		{ field: "updated", headerName: "updated", flex: 1 },
	];

	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
		id: false,
		jobId: false,
		erpId: true,
		genusCode: true,
		lineNumber: true,
		item: true,
		upc: true,
		name: true,
		jobStatus: false,
		workOrderStatus: true,
		jobType: false,
		locationId: false,
		unitOfMeasure: true,
		containerQuantity: true,
		productionQuantity: true,
		quantityProduced: true,
		startDate: true,
		deliveryDate: false,
		created: false,
		updated: false,
	});

	let workOrdersVariables: WorkOrdersQueryVariables = {
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: workOrderStatuses,
		first: 50,
		jobId: jobIds,
	};

	const { data: workOrders, isLoading: workOrdersLoading, error: workOrdersError } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (workOrdersLoading) return <div>Loading work orders...</div>;

	if (workOrdersError) return <div>Error: Getting WorkOrders</div>;

	const getWorkorder = (job: JobDocument) => {
		return workOrders?.workOrders?.nodes?.find(
			//(wo) => wo.jobId == job.id && (wo.workOrderStatus === WorkOrderStatus.New || wo.workOrderStatus === WorkOrderStatus.InProgress)
			(wo) => wo.jobId == job.id
		);
	};

	const handleRowClick = (
		params: any // GridRowParams
	) => {
		console.log("handleRowClick", params);
		handleInputChange(params.id, params.row.jobId);
	};

	let workOrderData = jobs
		.map((job) => {
			let workOrder = getWorkorder(job);
			if (!workOrder) return null;
			return {
				id: workOrder.id,
				jobId: job.id,
				lineNumber: workOrder?.lineNumber || 0,
				item: job.item.name,
				upc: job.item.upc,
				erpId: job.item.erpId,
				name: job.name,
				jobStatus: job.jobStatus,
				workOrderStatus: workOrder.workOrderStatus,
				jobType: job.jobType,
				locationId: job.locationId,
				unitOfMeasure: job.unitOfMeasure,
				containerQuantity: workOrder.containerQuantity,
				productionQuantity: workOrder.productionQuantity,
				quantityProduced: workOrder.quantityProduced || 0,
				genusCode: job.item.genusCode,
				startDate: workOrder.startDate,
				deliveryDate: job.deliveryDate,
				created: job.created,
				updated: job.updated,
			};
		})
		.filter((item) => item !== null)
		.sort((a, b) => (a.created > b.created ? 1 : -1));

	if (workOrderData.length === 0) {
		return (
			<SpacedGridItem maxCols={1}>
				<Alert severity="info">No workorders available to select</Alert>
			</SpacedGridItem>
		);
	}

	if (viewType === "table") {
		//create GridColDef based on JobDocument

		return (
			<SpacedGridItem maxCols={1}>
				<div style={{ width: "100%" }}>
					<DataGrid
						rows={workOrderData}
						columns={columns}
						autoHeight={true}
						columnVisibilityModel={columnVisibilityModel}
						onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
						onRowClick={handleRowClick}
					/>
				</div>
			</SpacedGridItem>
		);
	}

	return (
		<>
			{workOrderData.map((wod, i) => {
				if (!wod) return null;
				return (
					<SpacedGridItem key={i}>
						<SelectCard
							onClick={() => {
								handleRowClick({ id: wod.id, row: wod });
							}}
						>
							<Grid container spacing={1} alignItems="stretch" direction="row">
								<Grid item xs={8}>
									<Typography variant="h5" component="span" color="textPrimary">
										{wod.item}
									</Typography>
								</Grid>
								<Grid item xs={4} style={{ textAlign: "end" }}>
									<Chip size="small" label={getWorkOrderStatus(wod.workOrderStatus)} color={getWorkOrderChipColor(wod.workOrderStatus)} />
								</Grid>
								<Grid item xs={8}>
									<Typography variant="inherit" component="span" color="textSecondary">
										Workorder:
									</Typography>{" "}
									{wod.erpId}{" "}
								</Grid>
								<Grid item xs={4} style={{ textAlign: "end" }}>
									<Typography variant="inherit" component="span" color="textSecondary">
										Produced:
									</Typography>{" "}
									{wod.quantityProduced || "?"}
								</Grid>
								<Grid item xs={8}>
									<Typography variant="inherit" component="span" color="textSecondary">
										UPC:
									</Typography>{" "}
									{wod.upc}
								</Grid>
								<Grid item xs={4} style={{ textAlign: "end" }}>
									<Typography variant="inherit" component="span" color="textSecondary">
										Target:
									</Typography>{" "}
									{`${wod.containerQuantity}`}
								</Grid>
							</Grid>
						</SelectCard>
					</SpacedGridItem>
				);
			})}
		</>
	);
};

export default SelectWorkorder;
