import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { JobDocument, WorkOrderDocument, LocationDocument } from "~/services/graphql";

interface SetDownState {
	job?: JobDocument;
	workorder?: WorkOrderDocument;
	addingSetDown: boolean;
	editingSetDown: string;
	location?: LocationDocument;
	count: number;
}

const initialState: SetDownState = {
	addingSetDown: false,
	editingSetDown: "",
	count: 0,
};

export const setDownSlice = createSlice({
	name: "setDown",
	initialState,
	reducers: {
		openAddSetDown: (state) => {
			state.addingSetDown = true;
		},
		closeAddSetDown: (state) => {
			state.addingSetDown = false;
		},
		openEditSetDown: (state, action: PayloadAction<string>) => {
			state.editingSetDown = action.payload;
		},
		closeEditSetDown: (state) => {
			state.editingSetDown = "";
		},
		updateSetDownLocation: (state, action: PayloadAction<LocationDocument | null>) => {
			if (!action.payload) {
				state.location = undefined;
			} else {
				if (!state.location) {
					state.location = action.payload;
				} else {
					Object.assign(state.location, action.payload);
				}
			}
		},
		updateSetDownJob: (state, action: PayloadAction<JobDocument | null>) => {
			if (!action.payload) {
				state.job = undefined;
			} else {
				if (!state.job) {
					state.job = action.payload;
				} else {
					Object.assign(state.job, action.payload);
				}
			}
		},
		updateSetDownWorkorder: (state, action: PayloadAction<WorkOrderDocument | null>) => {
			if (!action.payload) {
				state.workorder = undefined;
			} else {
				if (!state.workorder) {
					state.workorder = action.payload;
				} else {
					Object.assign(state.workorder, action.payload);
				}
			}
		},
		updateSetDownCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload || 0;
		},
	},
});

export const {
	openAddSetDown,
	closeAddSetDown,
	openEditSetDown,
	closeEditSetDown,
	updateSetDownLocation,
	updateSetDownJob,
	updateSetDownWorkorder,
	updateSetDownCount,
} = setDownSlice.actions;

export default setDownSlice.reducer;
