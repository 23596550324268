import { useContext } from "react";

import { Button, Divider, Grid, Typography } from "@mui/material";
import SwitchUI from "@mui/material/Switch";
import { useConfirm } from "material-ui-confirm";

import SelectNative from "~/components/select/SelectNative";
import SpacedGridItem from "~/components/SpacedGridItem";
import { deleteDatabase } from "~/db";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { ThemeContext } from "~/providers/AppThemeProvider";
import { useSnacks } from "~/providers/SnackProvider";
import { updateSettings } from "~/store/settingsSlice";

const Settings = () => {
	const { themeType, setThemeType, themeSize, setThemeSize } = useContext(ThemeContext);
	const confirm = useConfirm();
	const [openSnack] = useSnacks();
	const dispatch = useAppDispatch();

	const numberOfProductionLines = useAppSelector((state) => state.settings.numberOfProductionLines);

	const handleThemeChange = (event: any) => {
		const { checked } = event.target;
		if (checked) {
			setThemeType("dark");
		} else {
			setThemeType("light");
		}
	};

	const handleThemeSizeChange = (size: string) => {
		setThemeSize(size);
	};

	const handleNumebrOfProductionLinesChange = (lines: string) => {
		console.log("lines", lines);
		let linesInt = parseInt(lines);
		if (linesInt < 1) {
			linesInt = 1;
		}
		dispatch(updateSettings({ numberOfProductionLines: linesInt }));
	};

	const handleDeleteDb = async () => {
		confirm({
			title: "Clear all data including saved counts?",
			cancellationButtonProps: { variant: "outlined" },
			confirmationButtonProps: { variant: "contained", color: "primary" },
		})
			.then(() => {
				deleteDatabase()
					.then(() => {
						openSnack("Database deleted", "success");
						window.location.reload();
					})
					.catch((err) => {
						console.error(err);
						openSnack("Database not deleted", "error");
					})
					.finally(() => {
						// Do what should be done next...
					});
			})
			.catch();
	};

	return (
		<Grid container alignItems="stretch" direction="row">
			<SpacedGridItem maxCols={3} smMargin>
				<Typography variant="h6">Dark Theme</Typography>
				<SwitchUI checked={themeType === "dark"} onChange={handleThemeChange} />
			</SpacedGridItem>
			<SpacedGridItem maxCols={3} smMargin>
				<Typography variant="h6">Text Size</Typography>
				<SelectNative
					noBlank
					sx={{ padding: 1 }}
					variant="outlined"
					label=""
					options={[
						{ value: 10, label: "1" },
						{ value: 12, label: "2" },
						{ value: 14, label: "3 (Default)" },
						{ value: 16, label: "4" },
						{ value: 18, label: "5" },
						{ value: 20, label: "6" },
					]}
					onChange={(e: any) => handleThemeSizeChange(e.target.value)}
					value={themeSize}
				/>
			</SpacedGridItem>
			<SpacedGridItem maxCols={3} smMargin>
				<Typography variant="h6"># of Production Lines</Typography>
				<SelectNative
					noBlank
					sx={{ padding: 1 }}
					variant="outlined"
					label=""
					options={[
						{ value: 1, label: "1" },
						{ value: 2, label: "2" },
						{ value: 3, label: "3" },
						{ value: 4, label: "4" },
						{ value: 5, label: "5" },
					]}
					onChange={(e: any) => handleNumebrOfProductionLinesChange(e.target.value)}
					value={numberOfProductionLines}
				/>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Divider sx={{ marginBottom: "4em" }} />
			</SpacedGridItem>
			<SpacedGridItem smMargin>
				<Button variant="outlined" onClick={() => handleDeleteDb()} color="inherit">
					Delete App Database
				</Button>
			</SpacedGridItem>
		</Grid>
	);
};
export default Settings;
