import { Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";

import SelectCard from "~/components/select/SelectCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDateTime, timestampFormatDayTime } from "~/helpers/timestampFormat";
import { useAppSelector } from "~/hooks/reduxHooks";
import { QueueItemState, getSortedQueueItems } from "~/store/queueSlice";

const formatterSetDown = (qi: QueueItemState) => {
	return (
		<SelectCard
			onClick={() => {
				return;
			}}
		>
			<Grid item xs={12} style={{ textAlign: "center" }}>
				<Typography variant="h6" component="span" color="textSecondary">
					Set Down
				</Typography>
			</Grid>
			<Grid container spacing={1} alignItems="stretch" direction="row">
				<Grid item xs={12}>
					<Typography variant="h6" component="h6" color="primary">
						{qi.displayData?.job?.item?.name}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="inherit" component="span" color="textPrimary">
						{qi.displayData?.location}
					</Typography>
				</Grid>
				<Grid item xs={6} style={{ textAlign: "end" }}>
					<Typography variant="inherit" component="span" color="textPrimary">
						{qi.displayData?.workOrder?.erpId || ""}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="inherit" component="span" color="textPrimary">
						Quantity: {qi.displayData?.count}
					</Typography>
				</Grid>

				<Grid item xs={6} style={{ textAlign: "end" }}>
					<Typography variant="inherit" component="span" color="textPrimary">
						Status: {qi.status}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="inherit" component="span" color="textSecondary">
						{timestampFormatDayTime(Number(qi.addedTimestamp))}
					</Typography>
				</Grid>
			</Grid>
		</SelectCard>
	);
};

const formatterProductionLine = (qi: QueueItemState) => {
	return (
		<SelectCard onClick={() => void 0}>
			<Grid container spacing={1} sx={{ minHeight: "120px" }}>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<Typography variant="h6" component="span" color="textSecondary">
								Production Count
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="h6" component="h6" color="primary">
								{qi.displayData?.job?.item?.name}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<b>Workorder:</b>
							<br /> {qi.displayData?.workOrder?.erpId || ""}
						</Grid>
						<Grid item xs={4}>
							<b>Line:</b> <br />
							{qi.displayData?.workOrder?.lineNumber}
						</Grid>
						<Grid item xs={4}>
							<b>Target</b> <br />
							{qi.displayData?.workOrder?.containerQuantity}
						</Grid>
						<Grid item xs={4}>
							<b>Started</b>
							<br />
							{stringFormatDateTime(qi.displayData?.workOrder.startDate)}
						</Grid>
						<Grid item xs={4}>
							<b>Produced</b> <br />
							{qi.displayData?.count}
						</Grid>
						<Grid item xs={4}>
							<b>Status</b> <br />
							{qi.status}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</SelectCard>
	);
};

const CountClipboard = () => {
	const queueItems = useAppSelector((state) => getSortedQueueItems(state));
	const viewType = useAppSelector((state) => state.layout.viewType);

	console.log("queueItems", queueItems);

	if (!queueItems.length) {
		<Typography variant="h6">Set Down Locations</Typography>;

		return <div>No SetDowns</div>;
	}

	if (viewType === "table") {
		const columns: GridColDef[] = [
			{ field: "id", headerName: "id", flex: 1 },
			{ field: "operationName", headerName: "Operation", flex: 1 },
			{
				field: "workOrder",
				headerName: "WorkOrder#",
				flex: 1,
				valueGetter: (_value, row) => row.displayData?.workOrder?.erpId || "",
			},
			{ field: "addedTimestamp", headerName: "Date", flex: 1, valueFormatter: (value?: string) => timestampFormatDayTime(Number(value)) },
			{ field: "location", headerName: "Location", flex: 1, valueGetter: (_value, row) => row.displayData?.location || "" },
			{ field: "count", headerName: "Count", flex: 1, valueGetter: (_value, row) => row.displayData?.count || "" },
			{ field: "status", headerName: "Status", flex: 1 },
		];

		const columnVisibility: GridColumnVisibilityModel = {
			id: false,
			operationName: true,
			workOrder: true,
			Date: true,
			Location: true,
			Count: true,
			Status: true,
		};

		return (
			<SpacedGridItem maxCols={1}>
				<Typography variant="h6">Locations</Typography>
				<div style={{ width: "100%" }}>
					<DataGrid
						rows={queueItems}
						columns={columns}
						autoHeight={true}
						columnVisibilityModel={columnVisibility}
						disableColumnFilter={true}
						disableColumnMenu={true}
						hideFooter={true}
					/>
				</div>
			</SpacedGridItem>
		);
	}

	return (
		<>
			<SpacedGridContainer>
				{queueItems.map((qi) => {
					if (!qi) return null;
					switch (qi.operationName) {
						case "SetDown":
							return formatterSetDown(qi);
						case "changeWorkOrderQuantityProducedMutation":
							return formatterProductionLine(qi);
						default:
							return null;
					}
				})}
			</SpacedGridContainer>
		</>
	);
};

export default CountClipboard;
